import PropTypes from 'prop-types';
import { isEmpty, filter, match, isNil, append } from 'ramda';
import escapeRegExp from 'lodash.escaperegexp';
import { truncate } from 'utils/strings';

import Presenter from 'utils/PropTypesPresenter';
import { flattenTree, findById, makeTreeFromFlattenArray, sortTreeByField } from 'utils/Utils';

export const PRE_K_12 = 'PreK-12';
export const HIGHER_EDUCATION = 'Higher Education';

export default new Presenter(
  {
    id: PropTypes.number,
    parent: PropTypes.shape(),
    depth: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.shape()),
    tagType: PropTypes.string,
    name: PropTypes.string,
    private: PropTypes.bool,
    sortOrder: PropTypes.number,
  },
  {
    truncateName(tag) {
      return truncate(tag.name, 20);
    },

    isSubject(tag) {
      return this.tagType(tag) === 'subject';
    },

    isPlatform(tag) {
      return this.tagType(tag) === 'platform';
    },

    isSoftwareType(tag) {
      return this.tagType(tag) === 'software_type';
    },

    isGradeLevel(tag) {
      return this.tagType(tag) === 'grade_level';
    },

    publish(tag) {
      return !this.private(tag);
    },

    searchTagsByName(tags, inputTerm) {
      const search = tag => !isEmpty(match(new RegExp(escapeRegExp(inputTerm), 'i'), tag.name));
      return filter(search, tags);
    },

    tagById(tags, id) {
      const flattenTags = flattenTree(tags);
      return findById(id)(flattenTags);
    },

    tagChildren(tag) {
      return flattenTree(tag.children);
    },

    tagParentsWithPluralChilds(tag, tags, parents = []) {
      const parentId = this.parent.id;
      if (isNil(parentId)) return parents;

      const parent = findById(parentId)(flattenTree(tags));
      if (this.children(parent).length < 2) return parents;

      return this.tagParentsWithPluralChilds(parent, tags, append(parent, parents));
    },

    sortedTags(tags) {
      const treeTags = makeTreeFromFlattenArray(tags);
      return sortTreeByField(treeTags, 'sortOrder');
    },

    isSingleSelect(tag) {
      return !isNil(tag.usedInSingleSelectTagGroup);
    },
  },
);
